import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';
import eventsImage from '../public/roundtable-events.png';

export default function Home() {
  return (
    <>
      <Head>
        <title>York Round Table | #DoMore</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="bg-white bg-opacity-5 py-10">
        <div className="mx-auto w-full max-w-screen-xl md:flex md:items-center px-6">
          <div className="md:w-1/2 md:pr-10">
            <h1 className="text-2xl md:text-3xl lg:text-5xl text-white mb-4">
              We&apos;re York, Ebor and County Round Table (65).
            </h1>
            <p className="text-white text-xl opacity-50 mb-8">
              A group of 18-45 year old men from York and the surrounding areas, who get together
              regularly to socialise and raise money through regular chartiable events.
            </p>
            <Link href="/about-us">
              <a className="inline-flex items-center px-6 py-3 border border-rt-orange text-white rounded-full">
                Learn more
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </Link>
          </div>
          <div className="md:w-1/2">
            <Image src={eventsImage} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
